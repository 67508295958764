import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Col, Container, Row } from "react-bootstrap"
import globalStyle from "../styles/global.module.css"

const useStateWithLocalStorageForEmail = localStorageKey => {
  //get email value from localStorage
  const [
    emailValueFromLocalStorage,
    setEmailValueFromLocalStorage,
  ] = React.useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
      ""
  );

  //set user email value in localStorage
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, emailValueFromLocalStorage)
  }, [emailValueFromLocalStorage]);

  return [emailValueFromLocalStorage, setEmailValueFromLocalStorage]
};

const useStateWithLocalStorageForLast4 = localStorageKey => {
  const [
    last4ValueFromLocalStorage,
    setLast4ValueFromLocalStorage,
  ] = React.useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
      ""
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, last4ValueFromLocalStorage)
  }, [last4ValueFromLocalStorage]);

  return [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage]
};

const BeautifulMotherhoodGuidePage = ({ data }) => {
  const [emailValueFromLocalStorage] = useStateWithLocalStorageForEmail("email");
  const [last4ValueFromLocalStorage] = useStateWithLocalStorageForLast4("last4");

  useEffect(() => {
    if (
      !emailValueFromLocalStorage &&
      !last4ValueFromLocalStorage &&
      typeof window !== "undefined"
    ) {
      window.location.replace("/become-a-member")
    }
  }, [emailValueFromLocalStorage, last4ValueFromLocalStorage]);

  return (
    <Layout className="site-content">
      <Container>
        <Row>
          <Col>
            <h1 className={globalStyle.entryTitle}>
              Beautiful Motherhood with Joy Monet Saunders
            </h1>
            <p className={globalStyle.paragraph}>
              Joy Monet Saunders is a wife, mama, speaker and coach committed to
              helping high-achieving, ambitious women attain their desired
              lifestyle and goals: from their career, home and relationships to
              everywhere in between.
            </p>
            <blockquote className={globalStyle.blockquote}>
              {" "}
              All MOMS on Maternity Club Members receive a complimentary
              Exploration Call ($145 value) with Joy Monet.
            </blockquote>
            <div>
              <iframe md={12}
                     src={"/pdf.js-gh-pages/web/viewer.xhtml?file=" + 
                       data.file.publicURL +
                       "#pagemode=none"
                     }
                     width="100%"
                     height="1200"
                     frameBorder="0"
                     marginWidth="0"
                     marginHeight="0"
                     scrolling="-webkit-overflow-scrolling: touch;"
                      // style={{
                      //   "overflow": "auto!important", "-webkit-overflow-scrolling": "touch!important",
                      //   "position": "relative", "width": "100%", "height": "500px", "top": 0, "left": 0,
                      // }}
                // style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;  toolbar: 0; scrollbar: 0; statusbar:0; messages:0; scrollbar:0 "
                     allowFullScreen
              ></iframe>
            </div>

          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    file(relativePath: { eq: "uploads/beautiful-motherhood.pdf" }) {
      publicURL
    }
  }
`;

export default BeautifulMotherhoodGuidePage
